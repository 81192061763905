import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import logo from './logo.png'

export default function Logout() {

  useEffect(() => {
    localStorage.removeItem("token");
    localStorage.removeItem("user");
    window.location.replace("/")
  }, [])

  return <></>
}
