import Loading from './components/loading'
import { useCallback, useEffect, useState } from 'react'
import { api } from './api.service'
import {
    DevicePhoneMobileIcon,
    DeviceTabletIcon,
    ComputerDesktopIcon
} from "@heroicons/react/24/solid"
import { PieChart, Pie, Legend, Tooltip, Cell, BarChart, Bar, XAxis, YAxis, CartesianGrid } from "recharts";
import { getCountryFromCoordinates } from 'utils/reverse-geolocation'

const retentionRateColors = ['#8884d8', '#82ca9d']

const churnRateColors = ['#82ca9d', '#8884d8']

const genresList = ['Pop', 'Rock', 'Hip-hop', 'Rap', 'Reggaeton', 'Afrobeats', 'Afropop', 'Electronica'];


export default function AdminAnalytics() {
    const [allData, setAllData] = useState([]);
    const [bounceRate, setBounceRate] = useState();
    const [isLoading, setIsLoading] = useState(true);
    const [mobileUsers, setMobileUsers] = useState([]);
    const [desktopUsers, setDesktopUsers] = useState([]);
    const [tabletUsers, setTabletUsers] = useState([]);
    const [allActiveUsers, setAllActiveUsers] = useState([]);
    const [dailyActiveUsers, setDailyActiveUsers] = useState([]);
    const [monthlyActiveUsers, setMonthlyActiveUsers] = useState([]);
    const [bounceRateData, setBounceRateData] = useState([]);
    const [retentionRate, setRetentionRate] = useState();
    const [retentionRateData, setRetentionRateData] = useState([]);
    const [churnRateData, setChurnRateData] = useState([]);
    const [conversionRateData, setConversionRateData] = useState([]);
    const [songData, setSongData] = useState([]);
    const [genresData, setGenresData] = useState([]);
    const [customersAgeData, setCustomersAgeData] = useState([])
    const [customersGenderData, setCustomersGenderData] = useState([])
    const [customersNationalityData, setCustomersNationalityData] = useState([])
    const [clickAboutCount, setClickAboutCount] = useState(0)
    const [isSongDataLoading, setIsSongDataLoading] = useState(false);

    const uniqueByEmail = (objectArray) => {
        const unique = objectArray.reduce((acc, obj) => {
            const email = obj.useremail;
            const onlineAt = parseInt(obj.onlineat, 10);

            // Check if the email already exists in the accumulator
            if (!acc[email] || parseInt(acc[email].onlineat, 10) < onlineAt) {
                acc[email] = { ...obj }; // Use the current object if the onlineat is larger or doesn't exist
            }

            return acc;
        }, {})
        return Object.values(unique);
    }

    const isWithinTenMinutes = (time) => {
        const tenMinutesInMs = 10 * 60 * 1000;

        // Get the current time in milliseconds
        const currentTime = Date.now();

        // Check if the value is within 5 minutes from now
        const isWithinTenMinutes = (currentTime - parseInt(time)) <= tenMinutesInMs;
        return isWithinTenMinutes;
    }

    const isActiveToday = (onlineAtTimestamp) => {
        const onlineAtDate = new Date(onlineAtTimestamp);
        const today = new Date();

        return onlineAtDate.getDate() === today.getDate() &&
            onlineAtDate.getMonth() === today.getMonth() &&
            onlineAtDate.getFullYear() === today.getFullYear();
    }

    const isActiveThisMonth = (onlineAtTimestamp) => {
        const onlineAtDate = new Date(onlineAtTimestamp);
        const today = new Date();
        const last30Days = new Date(today.setDate(today.getDate() - 30));

        return onlineAtDate >= last30Days;
    }

    const convertSongData = useCallback(async (startSongs, endSongs) => {
        async function getSongData(song) {
            const streamCount = startSongs.filter(s => s.songid === song.songid).length;
            const fullPlayCount = endSongs.filter(s => s.songid === song.songid).length;
            const streamDates = startSongs.filter(s => s.songid === song.songid).map(s => s.streamdate?.slice(0, -5));
            const allLocationData = startSongs.filter(s => s.songid === song.songid).map(s => s.geolocation) || [];
            const uniqueLocationData = [...new Set(allLocationData)]
            const countryOfOriginList = startSongs.filter(s => s.songid === song.songid).map(s => s.usercountryoforigin) || [];
            const livingInList = startSongs.filter(s => s.songid === song.songid).map(s => s.userlivingin) || [];
            const namesOnly = [];

            try {
                // Create an array of promises for each location data call
                const countryNames = await Promise.all(
                    uniqueLocationData.map(async (location) => {
                        const [lat, long] = location.split(',');
                        const countryName = await getCountryFromCoordinates(lat, long);
                        return countryName;
                    })
                );

                namesOnly.push(...countryNames); // Update names array once all calls are complete
            } catch (error) {
                console.error('Error fetching country data:', error);
            }

            return {
                songTitle: song.songtitle || song.songid,
                streamCount: streamCount,
                fullPlayCount: fullPlayCount,
                partPlayCount: streamCount - fullPlayCount,
                streamDates: streamDates,
                streamLocations: namesOnly,
                id: song.songid,
                countryOfOrigin: countryOfOriginList,
                countryOfResidence: livingInList
            }
        }

        const uniqueArray = startSongs.filter((value, index, self) =>
            index === self.findIndex((t) => (
                t.songid === value.songid
            ))
        )

        setIsSongDataLoading(true); // Start loading icon
        const songData = await Promise.all(uniqueArray.map(getSongData))
        setIsSongDataLoading(false);

        return songData;
    }, [])

    const convertGenresData = (startSongs) => {
        const genresDataArray = []
        genresList.map(genre => {
            const data = {
                type: genre,
                streamsCount: startSongs.filter(s => s.songgenres?.includes(genre.toLowerCase())).length,
                countries: removeDuplicatesAndUndefined(startSongs.map(s => s.usernationality))
            }
            genresDataArray.push(data);
        })
        return genresDataArray
    }

    const removeDuplicatesAndUndefined = (arr) => {
        return [...new Set(arr)].filter(item => item !== undefined);
    }

    useEffect(() => {
        setIsLoading(true)
        try {
            api(`${process.env.REACT_APP_API_URL}/admin/onlinestatus`).then(x => {
                setIsLoading(false);
                setAllData(JSON.parse(x.allData))
                setBounceRate(x.bounceRate);
            })

            api(`${process.env.REACT_APP_API_URL}/admin/retention-rate`).then(retentionRate => {
                setRetentionRate(retentionRate)
            })

            api(`${process.env.REACT_APP_API_URL}/admin/customers`).then(res => {
                setCustomersAgeData(res.customerAgeData)
                setCustomersGenderData(res.customerGenderData)
                setCustomersNationalityData(res.customerNationalityData)

            })
        } catch (e) { }
    }, []);

    useEffect(() => {
        const deviceTypeEvents = allData.filter(d => d.event_type === "deviceType").map(d => d.attributes);
        const startSongEvents = allData.filter(d => d.event_type === "startSong").map(d => d.attributes);
        const endSongEvents = allData.filter(d => d.event_type === "endSong").map(d => d.attributes);
        const clickAboutEvents = allData.filter(d => d.event_type === "clickAbout").map(d => d.attributes);
        const deviceTypeArray = deviceTypeEvents;
        const startSongEventsArray = startSongEvents;
        const endSongEventsArray = endSongEvents;
        const clickkAboutEventsArray = clickAboutEvents;
        const mobileEvents = deviceTypeArray.filter(d => d.devicetype === 'Mobile')
        const desktopEvents = deviceTypeArray.filter(d => d.devicetype === 'Desktop')
        const tabletEvents = deviceTypeArray.filter(d => d.devicetype === 'Tablet')
        const activeMobileUsers = uniqueByEmail(mobileEvents.filter(m => isWithinTenMinutes(m.onlineat)));
        const activeDesktopUsers = uniqueByEmail(desktopEvents.filter(m => isWithinTenMinutes(m.onlineat)));
        const activeTabletUsers = uniqueByEmail(tabletEvents.filter(m => isWithinTenMinutes(m.onlineat)));
        setDesktopUsers(activeDesktopUsers)
        setMobileUsers(activeMobileUsers)
        setTabletUsers(activeTabletUsers)
        setClickAboutCount(clickkAboutEventsArray?.length || 0);
        setAllActiveUsers(activeMobileUsers.length + activeDesktopUsers.length + activeTabletUsers.length || 1);

        setDailyActiveUsers(uniqueByEmail(deviceTypeArray.filter(d => isActiveToday(parseInt(d.onlineat)))))
        setMonthlyActiveUsers(uniqueByEmail(deviceTypeArray.filter(d => isActiveThisMonth(parseInt(d.onlineat)))))

        setBounceRateData(
            [
                { name: 'Single-page Sessions', value: bounceRate?.singlePageSessions || 0 },
                { name: 'Multi-page Sessions', value: (bounceRate?.totalSessions - bounceRate?.singlePageSessions) || 1 },
            ]
        )

        setRetentionRateData(
            [
                { name: 'Subscribers left', value: Math.abs(retentionRate?.subscribersAtEnd - retentionRate?.subscribersAtStart - retentionRate?.newSubscribers) || 0 },
                { name: 'Remaining Subscribers', value: Math.abs(retentionRate?.subscribersAtEnd - retentionRate?.newSubscribers) || 100 },
            ]
        )

        setChurnRateData(
            [
                { name: 'Lost Subscribers', value: Math.abs(retentionRate?.subscribersAtEnd - retentionRate?.subscribersAtStart - retentionRate?.newSubscribers) || 0 },
                { name: 'Remaining Subscribers', value: Math.abs(retentionRate?.subscribersAtEnd - retentionRate?.newSubscribers) || 100 },
            ]
        )

        setConversionRateData(
            [
                { name: 'New Subscribers', value: retentionRate?.newSubscribers || 0 },
                { name: 'Free Users at Start', value: retentionRate?.freeUsersAtStart || 1 },
            ]
        )

        convertSongData(startSongEventsArray, endSongEventsArray).then(setSongData);
        setGenresData(convertGenresData(startSongEventsArray))
    }, [allData, convertSongData])

    return (
        <>
            {isLoading && <Loading />}
            {!isLoading &&
                <div className='mx-auto max-w-screen-2xl p-4 md:p-6 2xl:p-10'>
                    <h4 className="text-xl font-semibold text-black dark:text-white mb-4">Current Active Users</h4>
                    <div className="grid grid-cols-1 gap-4 md:grid-cols-2 md:gap-6 xl:grid-cols-3 2xl:gap-7">
                        <div className='rounded-sm border border-stroke bg-white py-6 px-7 shadow-default dark:border-strokedark dark:bg-boxdark'>
                            <div className='flex h-11 w-11 items-center justify-center rounded-full bg-meta-2 dark:bg-meta-4'>
                                <DevicePhoneMobileIcon className='text-blue-500' />
                            </div>
                            <div className="mt-4 flex items-end justify-between">
                                <div>
                                    <h4 className="text-title-md font-bold text-black dark:text-white text-xl">{mobileUsers.length}</h4>
                                    <span className="text-sm font-medium">Mobile Users</span>
                                </div>
                                <span className="flex items-center gap-1 text-sm font-medium text-green-400">{(mobileUsers.length * 100 / allActiveUsers).toFixed(1)}%</span>
                            </div>
                        </div>
                        <div className='rounded-sm border border-stroke bg-white py-6 px-7 shadow-default dark:border-strokedark dark:bg-boxdark'>
                            <div className='flex h-11 w-11 items-center justify-center rounded-full bg-meta-2 dark:bg-meta-4'>
                                <ComputerDesktopIcon className='text-blue-500' />
                            </div>
                            <div className="mt-4 flex items-end justify-between">
                                <div>
                                    <h4 className="text-title-md font-bold text-black dark:text-white text-xl">{desktopUsers.length}</h4>
                                    <span className="text-sm font-medium">Desktop Users</span>
                                </div>
                                <span className="flex items-center gap-1 text-sm font-medium text-green-400">{(desktopUsers.length * 100 / allActiveUsers).toFixed(1)}%</span>
                            </div>
                        </div>
                        <div className='rounded-sm border border-stroke bg-white py-6 px-7 shadow-default dark:border-strokedark dark:bg-boxdark'>
                            <div className='flex h-11 w-11 items-center justify-center rounded-full bg-meta-2 dark:bg-meta-4'>
                                <DeviceTabletIcon className='text-blue-500' />
                            </div>
                            <div className="mt-4 flex items-end justify-between">
                                <div>
                                    <h4 className="text-title-md font-bold text-black dark:text-white text-xl">{tabletUsers.length}</h4>
                                    <span className="text-sm font-medium">Tablet Users</span>
                                </div>
                                <span className="flex items-center gap-1 text-sm font-medium text-green-400">{(tabletUsers.length * 100 / allActiveUsers).toFixed(1)}%</span>
                            </div>
                        </div>
                    </div>

                    <h4 className="text-xl font-semibold text-black dark:text-white mt-10 mb-4">Daily / Monthly Active Users</h4>
                    <div className='flex gap-6'>
                        <div className="flex w-full px-4">
                            <span className="mt-1 mr-2 flex h-4 w-full max-w-4 items-center justify-center rounded-full border border-[#3c50e0]">
                                <span className="block h-2.5 w-full max-w-2.5 rounded-full bg-[#3c50e0]"></span>
                            </span>
                            <div className="w-full">
                                <p className="font-semibold text-[#3c50e0]">Daily Active Users</p>
                                <p className="text-2xl font-bold px-2 mt-2">{dailyActiveUsers.length}</p>
                            </div>
                        </div>
                        <div className="flex w-full px-4">
                            <span className="mt-1 mr-2 flex h-4 w-full max-w-4 items-center justify-center rounded-full border border-[#80caee]">
                                <span className="block h-2.5 w-full max-w-2.5 rounded-full bg-[#80caee]"></span>
                            </span>
                            <div className="w-full">
                                <p className="font-semibold text-[#80caee]">Monthly Active Users</p>
                                <p className="text-2xl font-bold px-2 mt-2">{monthlyActiveUsers.length}</p>
                            </div>
                        </div>
                        <div className="flex w-full px-4">
                            <span className="mt-1 mr-2 flex h-4 w-full max-w-4 items-center justify-center rounded-full border border-[#82ca9d]">
                                <span className="block h-2.5 w-full max-w-2.5 rounded-full bg-[#82ca9d]"></span>
                            </span>
                            <div className="w-full">
                                <p className="font-semibold text-[#82ca9d]">About KaZang Click Count</p>
                                <p className="text-2xl font-bold px-2 mt-2">{clickAboutCount}</p>
                            </div>
                        </div>
                    </div>

                    <h4 className="text-xl font-semibold text-black dark:text-white mt-10 mb-4">Various Rates</h4>

                    <div className="grid grid-cols-1 gap-4 md:grid-cols-2 md:gap-6 xl:grid-cols-4 2xl:gap-7">
                        <div className='flex flex-col justify-center items-center rounded-sm border border-stroke bg-white py-6 px-7 shadow-default dark:border-strokedark dark:bg-boxdark'>
                            <PieChart width={200} height={200}>
                                <Pie
                                    dataKey="value"
                                    isAnimationActive={false}
                                    data={retentionRateData}
                                    cx={100}
                                    cy={100}
                                    innerRadius={30}
                                    outerRadius={60}
                                    fill="#8884d8"
                                    label
                                >
                                    {retentionRateData.map((entry, index) => (
                                        <Cell key={`cell-${index}`} fill={retentionRateColors[index % retentionRateColors.length]} />
                                    ))}
                                </Pie>
                                <Tooltip />
                            </PieChart>
                            <h4 className='text-xl font-semibold text-[#8884d8] dark:text-white'>Retention Rate: {retentionRate?.retentionRate}</h4>
                        </div>
                        <div className='flex flex-col justify-center items-center rounded-sm border border-stroke bg-white py-6 px-7 shadow-default dark:border-strokedark dark:bg-boxdark'>
                            <PieChart width={200} height={200}>
                                <Pie
                                    dataKey="value"
                                    isAnimationActive={false}
                                    data={churnRateData}
                                    cx={100}
                                    cy={100}
                                    innerRadius={30}
                                    outerRadius={60}
                                    fill="#82ca9d"
                                    label
                                >
                                    {churnRateData.map((entry, index) => (
                                        <Cell key={`cell-${index}`} fill={churnRateColors[index % churnRateColors.length]} />
                                    ))}
                                </Pie>
                                <Tooltip />
                            </PieChart>
                            <h4 className='text-xl font-semibold text-[#82ca9d] dark:text-white'>Churn Rate: {100 - parseInt(retentionRate?.retentionRate)}%</h4>
                        </div>
                        <div className='flex flex-col justify-center items-center rounded-sm border border-stroke bg-white py-6 px-7 shadow-default dark:border-strokedark dark:bg-boxdark'>
                            <PieChart width={200} height={200}>
                                <Pie
                                    dataKey="value"
                                    isAnimationActive={false}
                                    data={bounceRateData}
                                    cx={100}
                                    cy={100}
                                    innerRadius={30}
                                    outerRadius={60}
                                    fill="#0fadcf"
                                    label
                                />
                                <Tooltip />
                            </PieChart>
                            <h4 className='text-xl font-semibold text-[#0fadcf] dark:text-white'>Bounce Rate: {bounceRate?.bounceRate || 0}%</h4>
                        </div>
                        <div className='flex flex-col justify-center items-center rounded-sm border border-stroke bg-white py-6 px-7 shadow-default dark:border-strokedark dark:bg-boxdark'>
                            <PieChart width={200} height={200}>
                                <Pie
                                    dataKey="value"
                                    isAnimationActive={false}
                                    data={conversionRateData}
                                    cx={100}
                                    cy={100}
                                    innerRadius={30}
                                    outerRadius={60}
                                    fill="#3c50e0"
                                    label
                                />
                                <Tooltip />
                            </PieChart>
                            <h4 className='text-xl font-semibold text-[#3c50e0] dark:text-white'>Conversion Rate: {retentionRate?.conversionRate}%</h4>
                        </div>
                    </div>

                    {/* Table Section */}
                    <h4 className="text-xl font-semibold text-black dark:text-white mt-10 mb-4">Song Data</h4>

                    <div className="flex flex-col bg-white">
                        <div className="grid grid-cols-12 border-t border-stroke py-4 px-4 dark:border-strokedark md:px-6 2xl:px-7">
                            <div className="col-span-1 flex items-center">
                                <p className="font-medium">No</p>
                            </div>
                            <div className="col-span-2 flex items-center">
                                <p className="font-medium">Song Title</p>
                            </div>
                            <div className="col-span-1 hidden items-center sm:flex">
                                <p className="font-medium">Streams Count</p>
                            </div>
                            <div className="col-span-1 flex items-center">
                                <p className="font-medium">Full-Play Count</p>
                            </div>
                            <div className="col-span-1 flex items-center">
                                <p className="font-medium">Part-Play Count</p>
                            </div>
                            <div className="col-span-2 flex items-center">
                                <p className="font-medium">Stream Dates</p>
                            </div>
                            <div className="col-span-2 flex items-center">
                                <p className="font-medium">Stream Locations</p>
                            </div>
                            <div className="col-span-1 flex items-center">
                                <p className="font-medium">Country of Orign</p>
                            </div>
                            <div className="col-span-1 flex items-center">
                                <p className="font-medium">Country of Residence</p>
                            </div>
                        </div>
                        <div className='flex flex-col max-h-[800px] overflow-auto'>
                            {isSongDataLoading && <div className='flex justify-center items-center pb-8'>
                                <Loading />
                            </div>}
                            {!isSongDataLoading && songData.map((song, key) => (
                                <div
                                    className="grid grid-cols-12 border-t border-stroke py-4 px-4 dark:border-strokedark md:px-6 2xl:px-7"
                                    key={key}
                                >
                                    <div className="col-span-1 items-center">
                                        <p className="text-sm text-black dark:text-white">
                                            {key + 1}
                                        </p>
                                    </div>
                                    <div className="col-span-2 flex">
                                        <div className="flex flex-col gap-4 sm:flex-row">
                                            <span className="text-sm text-black dark:text-white" style={{"overflow-wrap": "anywhere"}}>
                                                {song.songTitle}
                                            </span>
                                        </div>
                                    </div>
                                    <div className="col-span-1 sm:flex">
                                        <p className="text-sm text-black dark:text-white">
                                            {song.streamCount}
                                        </p>
                                    </div>
                                    <div className="col-span-1 flex">
                                        <p className="text-sm text-black dark:text-white">
                                            {song.fullPlayCount}
                                        </p>
                                    </div>
                                    <div className="col-span-1 flex">
                                        <p className="text-sm text-black dark:text-white">{song.partPlayCount}</p>
                                    </div>
                                    <div className="col-span-2 flex flex-col max-h-[100px] overflow-y-auto">
                                        {song.streamDates.length > 0 && song.streamDates.map((date, index) => (
                                            <p className="text-sm text-meta-3" key={index}>{date}</p>
                                        ))}
                                    </div>
                                    <div className="col-span-2">
                                        {song.streamLocations.length > 0 && song.streamLocations.map((date, index) => (
                                            <p className="text-sm text-meta-3" key={index}>{date}</p>
                                        ))}
                                    </div>
                                    <div className="col-span-1 flex flex-col max-h-[100px] overflow-y-auto">
                                        {song.countryOfOrigin?.length > 0 && song.countryOfOrigin.map((country, index) => (
                                            <p className="text-sm text-meta-3" key={index}>{country}</p>
                                        ))}
                                    </div>
                                    <div className="col-span-1 flex flex-col max-h-[100px] overflow-y-auto">
                                        {song.countryOfResidence?.length > 0 && song.countryOfResidence.map((country, index) => (
                                            <p className="text-sm text-meta-3" key={index}>{country}</p>
                                        ))}
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>

                    {/* Genres Section */}
                    <h4 className="text-xl font-semibold text-black dark:text-white mt-10 mb-4">Genres Data</h4>

                    <div className="grid grid-cols-1 gap-4 md:grid-cols-2 md:gap-6 xl:grid-cols-4 2xl:gap-7">
                        {genresData.map((genre, key) => (
                            <div key={key} className='rounded-sm border border-stroke bg-white py-6 px-7 shadow-default dark:border-strokedark dark:bg-boxdark'>
                                <div className='flex items-center justify-center rounded-full border-b border-b-stroke text-xl font-bold'>
                                    {genre.type}
                                </div>
                                <div className="mt-4 flex flex-col gap-2">
                                    <div className='flex justify-between'>
                                        <span className="text-sm font-medium">Total Streams: </span>
                                        <span className="flex items-center gap-1 text-sm font-medium text-blue-400">{genre.streamsCount}</span>
                                    </div>
                                    <div className='flex justify-between gap-4'>
                                        <span className="text-sm font-medium">Stream Countries: </span>
                                        <div className='flex flex-col gap-2'>
                                            <div key={key} className="flex text-sm font-medium text-green-400 justify-end">
                                                {genre.countries.join(',')}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>

                    {/* Customers Section */}
                    <h4 className="text-xl font-semibold text-black dark:text-white mt-10 mb-4">Customers Data</h4>

                    <div className="grid grid-cols-1 gap-4 md:grid-cols-2 md:gap-6 xl:grid-cols-3 2xl:gap-7">
                        <div className='rounded-sm border border-stroke bg-white py-6 px-7 shadow-default dark:border-strokedark dark:bg-boxdark'>
                            <div className='flex items-center justify-center rounded-full border-b border-b-stroke text-xl font-bold'>
                                Customer Age
                            </div>
                            <BarChart
                                width={300}
                                height={300}
                                data={customersAgeData}
                                margin={{
                                    top: 40,
                                    right: 10,
                                    left: 10,
                                    bottom: 20
                                }}
                            >
                                <CartesianGrid strokeDasharray="3 3" />
                                <XAxis dataKey="age" />
                                <YAxis />
                                <Tooltip />
                                <Legend />
                                <Bar dataKey="count" fill="#8884d8">
                                </Bar>
                            </BarChart>
                        </div>
                        <div className='rounded-sm border border-stroke bg-white py-6 px-7 shadow-default dark:border-strokedark dark:bg-boxdark'>
                            <div className='flex items-center justify-center rounded-full border-b border-b-stroke text-xl font-bold'>
                                Customer Gender
                            </div>
                            <BarChart
                                width={300}
                                height={300}
                                data={customersGenderData}
                                margin={{
                                    top: 40,
                                    right: 10,
                                    left: 10,
                                    bottom: 20
                                }}
                            >
                                <CartesianGrid strokeDasharray="3 3" />
                                <XAxis dataKey="type" />
                                <YAxis />
                                <Tooltip />
                                <Legend />
                                <Bar dataKey="count" fill="#82ca9d">
                                </Bar>
                            </BarChart>
                        </div>
                        <div className='rounded-sm border border-stroke bg-white py-6 px-7 shadow-default dark:border-strokedark dark:bg-boxdark'>
                            <div className='flex items-center justify-center rounded-full border-b border-b-stroke text-xl font-bold'>
                                Customer Nationality
                            </div>
                            <BarChart
                                width={300}
                                height={300}
                                data={customersNationalityData}
                                margin={{
                                    top: 40,
                                    right: 10,
                                    left: 10,
                                    bottom: 20
                                }}
                            >
                                <CartesianGrid strokeDasharray="3 3" />
                                <XAxis dataKey="country" />
                                <YAxis />
                                <Tooltip />
                                <Legend />
                                <Bar dataKey="count" fill="pink">
                                </Bar>
                            </BarChart>
                        </div>
                    </div>
                </div>
            }
        </>
    )
}