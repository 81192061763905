import * as Icons from "react-icons/tb";
import { record } from 'aws-amplify/analytics';
import { Link } from "react-router-dom";
const MobileNavItem = ({ icon, text, path, onClick }) => {
  // const Icon = Icons[icon];
  const user = JSON.parse(localStorage.getItem('user'));
  return (
    <li className="w-full text-center">
      <Link
        to={path}
        className="flex flex-row items-center text-xl gap-2 w-full"
        onClick={(e) => {
          if (onClick) {
            onClick(e);
          }
          if (path === '/about') {
            record({
              name: 'clickAbout',
              attributes: {
                userId: user?.id,
              }
            });
          }
        }}
      >
        <span>
          {" "}
          {/* <Icon />{" "} */
          icon()
          }
        </span>
        {text}
      </Link>
    </li>
  );
};

export default MobileNavItem;
