import { UserIcon, XMarkIcon } from "@heroicons/react/24/outline";
import ImageViewer from "./image-viewer";

export default function MemberCard({ x, remove, displayDelete }) {

    return <div className="flex flex-col overflow-hidden text-white rounded shadow bg-neutral-700">
        <div className="p-3">
            <div className="flex items-center justify-between">
                <div className="flex space-x-2">
                    {x.profilePicture && <ImageViewer className="w-16 h-16 rounded-lg" image={x.profilePicture}></ImageViewer>}
                    {!x.profilePicture && <UserIcon className="w-16 h-16 p-2 bg-black rounded-lg" image={x.profilePicture}></UserIcon>}
                    <div className="sm:hidden">
                    <div className="text-xl font-semibold text-white">{x.name}</div>
                <div className="text-sm font-light text-light">{x.bio}</div>
                    </div>
                </div>
                <div className="cursor-pointer text-light">
                    {displayDelete && <div className="p-1 bg-red-700 rounded-full shadow" onClick={(e) => { e.stopPropagation(); remove() }}>
                        <XMarkIcon title="Remove from " className="inline w-8 hover:opacity-70" ></XMarkIcon>
                    </div>}
                </div>
            </div>

            <div className="flex-1 hidden mt-3 sm:block">

                <div className="text-xl font-semibold text-white">{x.name}</div>
                <div className="text-sm font-light text-light">{x.bio}</div>

            </div>
        </div>




    </div>
}

