import { CheckIcon, UserIcon, UserMinusIcon, UserPlusIcon, XMarkIcon } from "@heroicons/react/24/outline";
import { useNavigate } from "react-router-dom";
import ImageViewer from "./image-viewer";

export default function FriendCard({ x, ownUserData, requests, removeContact, sendRequest, acceptRequest, rejectRequest }) {

    const navigate = useNavigate();

    return <div onClick={() => navigate('/profile/' + x._id)} className="flex flex-col overflow-hidden text-white rounded shadow cursor-pointer bg-neutral-700">
        {!ownUserData?.contacts?.find(y => x._id == y._id) && requests?.requests?.find(y => y.requester + '' == x._id + '') && <div className="p-2 font-medium text-center text-white bg-purple-500">Received Friend Request</div>}


        <div className="p-3">
            <div className="flex items-center justify-between">
                <div>
                    {x.profilePicture && <ImageViewer className="w-16 h-16 rounded-lg" image={x.profilePicture}></ImageViewer>}
                    {!x.profilePicture && <UserIcon className="w-16 h-16 p-2 bg-black rounded-lg" image={x.profilePicture}></UserIcon>}
                </div>
                <div className="cursor-pointer text-light">
                    {/* Is in contacts */}
                    {ownUserData?.contacts?.find(y => x._id == y._id) && <>
                        <UserMinusIcon title="Remove Friend" className="inline w-8 hover:opacity-70" onClick={(e) => { e.stopPropagation(); removeContact(x._id) }}></UserMinusIcon>
                    </>}

                    {/* Not in contacts */}
                    {!ownUserData?.contacts?.find(y => x._id == y._id) && <>
                        {/* User has sent us a request */}
                        {requests?.requests?.find(y => y.requester + '' == x._id + '') && <div className="flex space-x-4">
                            <div className="p-1 bg-green-700 rounded-full shadow" onClick={(e) => { e.stopPropagation(); acceptRequest(requests?.requests?.find(y => y.requester + '' == x._id + '')._id) }}>
                                <CheckIcon title="Accept Friend Request" className="inline w-8 hover:opacity-70" ></CheckIcon>

                            </div>
                            <div className="p-1 bg-red-700 rounded-full shadow" onClick={(e) => { e.stopPropagation(); rejectRequest(requests?.requests?.find(y => y.requester + '' == x._id + '')._id) }}>
                                <XMarkIcon title="Reject Friend Request" className="inline w-8 hover:opacity-70" ></XMarkIcon>

                            </div>

                        </div>}

                        {/* User has not sent us a request */}
                        {!requests?.requests?.find(y => y.requester + '' == x._id + '') && <>
                            {requests?.sentRequests?.find(y => y.requestee + '' == x._id + '') && <>
                                Request Pending
                            </>}
                            {!requests?.sentRequests?.find(y => y.requestee + '' == x._id + '') && <>
                                <UserPlusIcon title="Send friend request" className="inline w-8 hover:opacity-70" onClick={(e) => { e.stopPropagation(); sendRequest(x._id) }}></UserPlusIcon>
                            </>}
                        </>}

                    </>}
                </div>
            </div>

            <div className="flex-1 mt-3">

                <div className="text-xl font-semibold text-white">{x.name}</div>
                <div className="text-sm font-light text-light">{x.bio}</div>

            </div>
        </div>
    </div>
}

