import React, { useState, useEffect } from "react";
import { Route, Routes } from "react-router-dom";
import About from "./about";
import AdminLayout from "./admin-layout";
import AdminUsers from "./admin-users";
import { api } from "./api.service";
import Chart from "./chart";
import FAQs from "./faqs";
import PlaylistForm from "./forms/playlist-form";
import ProfileForm from "./forms/profile-form";
import SongForm from "./forms/song-form";
import UserForm from "./forms/user-form";
import Friends from "./friends";
import Home from "./home";
import Layout from "./layout";
import Library from "./library";
import Login from "./login";
import Logout from "./logout";
import Playlist from "./playlist";
import Playlists from "./playlists";
import Profile from "./profile";
import Register from "./register";
import RequestReset from "./request-reset";
import ResetPassword from "./reset-password";
import Search from "./search";
import Song from "./song";
import AdminAbout from "./admin-about";
import Genre from "./genre";
import Subscription from "./sub";
import { trackSongEvent } from "./utils/track-song";
import AdminAnalytics from "admin-analytics";

const PlayerContext = React.createContext();
const { Provider } = PlayerContext;

export const PlayerProvider = ({ children }) => {
  const [isPlaying, setIsPlaying] = useState(false)
  const [song, _setSong] = useState(null)
  const [history, setHistory] = useState([])
  const [queue, setQueue] = useState([])
  const [mode, setMode] = useState("Random")
  const [modeSelection, setModeSelection] = useState("South Korea");

  const setSong = async (song) => {
    setIsPlaying(true);
    _setSong(song);
    setHistory([...history, song]);
    trackSongEvent('startSong', song)
  }
  const previous = () => {
    if (history.length > 0) {
      setIsPlaying(true);
      _setSong(history[history.length - 2]);
      setHistory([...history].slice(0, -1))
    }
  }
  const nextSong = async () => {
    if(process.env.NODE_ENV === "development") {console.log(`MODE: ${mode} |||| Selection: ${modeSelection}`)}

    if (queue?.length) {
      setSong(queue[0])
      setQueue([...queue.slice(1)])
    } else if (mode === "Genre") {
      let foundSong = await api(`${process.env.REACT_APP_API_URL}/public/genre/${modeSelection}`).then(x => {
        const items = x.songs.filter(y => y.name !== song?.name && y?.url?.split('v=')[1]?.length > 0)
        let item = items[Math.floor(Math.random() * items.length)];
        return { ...item, id: item?.url?.split('v=')[1] }
      })
      if(process.env.NODE_ENV === "development") {console.log("Playing a song in genre: " + modeSelection);}
      setSong(foundSong)
    } else {
      let song = await api(`${process.env.REACT_APP_API_URL}/public`).then(x => {
        let items;
        if(mode === "Chart") {
          items = x.songs.filter(y => y.chart === modeSelection && y?.url?.split('v=')[1]?.length > 0)
        } else {
          items = x.songs.filter(y => y.rank && +y.rank > 0 && y?.url?.split('v=')[1]?.length > 0)
        }

        let item = items[Math.floor(Math.random() * items.length)];
        return { ...item, id: item?.url?.split('v=')[1] }
      })

      if(process.env.NODE_ENV === "development") {console.log("Playing a song from chart: " + song.chart);}

      setSong(song)
    }
  }

  const toggle = () => setIsPlaying(v => !v)
  const stopPlaying = () => setIsPlaying(false)
  const startPlaying = () => setIsPlaying(true)
  function ChangeMode(mode, selection) {
    setMode(mode);
    setModeSelection(selection);
    if(process.env.NODE_ENV === "development") {console.log("MODE SET TO: " + mode + " | SELECTION: " + selection);}
  }

  return (
    <Provider value={{ isPlaying, toggle, song, setSong, setIsPlaying, previous, stopPlaying, startPlaying, setQueue, nextSong, ChangeMode }}>
      {children}
    </Provider>
  )
}

export const usePlayer = () => React.useContext(PlayerContext)

export default function App() {

  return (
    <PlayerProvider>
      <Routes>
        <Route path="/login" element={<Login />} />
        <Route path="/register" element={<Register />} />
        <Route path="/request-reset" element={<RequestReset />} />
        <Route path="/passwordReset" element={<ResetPassword />} />
        <Route path="/logout" element={<Logout />} />

        <Route path='/' element={<Layout />}>
          <Route path="/" element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/faqs" element={<FAQs />} />
          <Route path="/genre/:name" element={<Genre />} />
          <Route path="/chart/:country" element={<Chart />} />
          <Route path="/song/:id" element={<Song />} />
          <Route path="/profile/:id" element={<Profile />} />
          <Route path="/friends" element={<Friends />} />
          <Route path="/settings" element={<ProfileForm />} />
          <Route path="/library" element={<Library />} />
          <Route path="/playlists" element={<Playlists />} />
          <Route path="/add-song" element={<SongForm />} />
          <Route path="/create-playlist/:id" element={<PlaylistForm />} />
          <Route path="/playlist/:id" element={<Playlist />} />
          <Route path="/search" element={<Search />} />
          <Route path="/subscription" element={<Subscription />} />
        </Route>

        <Route path='/admin' element={<AdminLayout></AdminLayout>}>
          <Route path="/admin" element={<AdminUsers></AdminUsers>} />
          <Route path="/admin/about" element={<AdminAbout></AdminAbout>} />
          <Route path="/admin/analytics" element={<AdminAnalytics />} />
          <Route path="/admin/manage-user/:id" element={<UserForm></UserForm>} />
        </Route>

      </Routes>
    </PlayerProvider>


  )
}
