import { create } from "zustand";

export const useNavStore = create((set) => ({
  availableCountries: [],
  allSongs: [],
  setAvailableCountries: (newList) =>
    set((state) => ({ availableCountries: newList })),
  setAllSongs: (songList) => {
    set((state) => ({ allSongs: songList }));
  },
}));

export const useSongStore = create((set) => ({
  allSongs: [],
  setAllSongs: (songList) => {
    set((state) => ({ allSongs: songList }));
  },
}));
