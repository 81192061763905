import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { EyeIcon, EyeSlashIcon } from '@heroicons/react/24/outline';
import logo from './logo.png';

export default function ResetPassword() {

  const [values, setValues] = useState({});
  const [error, setError] = useState("")
  const [searchParams, setSearchParams] = useSearchParams();
  const [showPassword, setShowPassword] = useState(false);
  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  useEffect(() => {
    setValues({ ...values, token: searchParams.get('token'), id: searchParams.get('id') })
  }, []);

  const submit = async () => {
    await fetch(`${process.env.REACT_APP_API_URL}/auth/reset-password`, {
      method: 'POST',
      headers: new Headers({
        'Content-Type': 'application/json'
      }),
      body: JSON.stringify(values)
    }).then(res => { return res.json() }).then(x => {
      if (x.status === "success") {
        window.location.replace("/login")

      }
      else {
        setError("Invalid Token - Password Reset Failed")
      }
    })
  }

  return (
    <>
      <div className="flex flex-col justify-center min-h-full py-12 sm:px-6 lg:px-8">
        <div className="sm:mx-auto sm:w-full sm:max-w-md">
          <img
            className="w-auto mx-auto h-44"
            src={logo}
            alt="Your Company"
          />
          <h2 className="mt-24 text-3xl font-bold tracking-tight text-center text-purple-500">Reset Password</h2>
        </div>

        <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
          <div className="px-4 py-8 bg-white sm:rounded-lg sm:px-10">
            <div className="space-y-6">
              <div>
                <label className="block text-sm font-medium text-center text-purple-700">
                  {error}
                </label>
              </div>

              <div>
                <label htmlFor="password" className="block text-sm font-medium text-purple-700">
                  New Password
                </label>
                <div className="mt-1 relative">
                  <input value={values.password} onChange={(e) => setValues({ ...values, password: e.target.value })}
                    id="password"
                    name="password"
                    type={showPassword ? 'text' : 'password'}
                    autoComplete="current-password"
                    required
                    className="block w-full px-3 py-2 placeholder-purple-400 border border-purple-300 rounded shadow-sm appearance-none focus:border-purple-500 focus:outline-none focus:ring-purple-500 sm:text-sm"
                  />
                  <button
                    type="button"
                    onClick={togglePasswordVisibility}
                    className="absolute inset-y-0 right-0 flex items-center pr-3"
                  >
                    {showPassword ? (
                      <EyeSlashIcon className="h-5 w-5 text-neutral-500" aria-hidden="true" />
                    ) : (
                      <EyeIcon className="h-5 w-5 text-neutral-500" aria-hidden="true" />
                    )}
                  </button>
                </div>
              </div>

              <div>
                <button
                  onClick={() => submit()}
                  type="button"
                  className="flex justify-center w-full px-4 py-2 text-sm font-medium text-white bg-purple-500 border border-transparent rounded shadow-sm hover:bg-purple-500 focus:outline-none focus:ring-2 focus:ring-purple-500 focus:ring-offset-2"
                >
                  Submit
                </button>
              </div>
            </div>

          </div>
        </div>
      </div>
    </>
  )
}
