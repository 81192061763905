import { record } from 'aws-amplify/analytics';
import { getUserGeolocation } from './geolocation';

export const trackSongEvent = async (eventName, song) => {
    const geolocation = await getUserGeolocation();
    const user = JSON.parse(localStorage.getItem('user'));

    const date = new Date();

    let hour = date.getHours();
    hour = (hour < 10 ? "0" : "") + hour;
    let min  = date.getMinutes();
    min = (min < 10 ? "0" : "") + min;
    let sec  = date.getSeconds();
    sec = (sec < 10 ? "0" : "") + sec;

    // Send data to Pinpoint
    record({
        name: eventName,
        attributes: {
            songId: song._id,
            songGenres: JSON.stringify(song.genres),
            songTitle: song.name,
            streamDate: new Date(),  // Date of stream
            streamTime: hour + ':' + min + ':' + sec,  // Date of stream
            geolocation: geolocation,  // User geolocation
            userId: user?.id,
            userAge: user?.age,
            userGender: user?.gender,
            userNationality: user?.from,
            userCountryOfOrigin: user?.countryOfOrigin,
            userLivingIn: user?.livingIn,
        }
    });
};