import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { api } from "./api.service";
import { usePlayer } from "./App";
import Button from "./components/button";
import FriendCard from "./components/friend-card";
import TextField from "./forms/fields/text-field";
import FormModal from "./forms/form-modal";
import { toast } from "react-toastify";

export default function Friends() {
  const [data, setData] = useState();
  const [value, setValue] = useState();
  const [requests, setRequests] = useState();
  const [ownUserData, setOwnUserData] = useState();
  const [inviteOpen, setInviteOpen] = useState(false);
  const [inviteEmail, setInviteEmail] = useState("");
  const params = useParams();
  const navigate = useNavigate();
  const player = usePlayer();

  const getYoutubeVideoId = (url) => {
    return url.split("v=")[1];
  };

  const getResults = () => {
    api(`${process.env.REACT_APP_API_URL}/user/user-search/${value}`).then(
      (x) => {
        setData(x);
      },
    );
  };

  const inviteUser = () => {
    api(`${process.env.REACT_APP_API_URL}/user/invite/${inviteEmail}`).then(
      (x) => {
        setInviteEmail("");
        setInviteOpen(false);
      },
    );
  };

  const removeContact = (id) => {
    api(`${process.env.REACT_APP_API_URL}/user/remove-friend/${id}`).then(
      (x) => {
        api(`${process.env.REACT_APP_API_URL}/user`).then((x) => {
          setOwnUserData(x);
        });
      },
    );
  };

  const sendRequest = (id) => {
    api(`${process.env.REACT_APP_API_URL}/user/send-request/${id}`, {
      message: "",
    })
      .then((x) => {
        api(`${process.env.REACT_APP_API_URL}/user`).then((x) => {
          setOwnUserData(x);
        });
        api(`${process.env.REACT_APP_API_URL}/user/get-requests`).then((x) => {
          setRequests(x);
        });
      })
      .then((x) => {
        toast.info("Friend request sent");
      });
  };

  const acceptRequest = (id) => {
    api(`${process.env.REACT_APP_API_URL}/user/accept-request/${id}`)
      .then((x) => {
        api(`${process.env.REACT_APP_API_URL}/user`).then((x) => {
          setOwnUserData(x);
        });
        api(`${process.env.REACT_APP_API_URL}/user/get-requests`).then((x) => {
          setRequests(x);
        });
      })
      .then((x) => {
        toast.success("Friend request accepted");
      });
  };

  const rejectRequest = (id) => {
    api(`${process.env.REACT_APP_API_URL}/user/reject-request/${id}`).then(
      (x) => {
        api(`${process.env.REACT_APP_API_URL}/user`).then((x) => {
          setOwnUserData(x);
        });
        api(`${process.env.REACT_APP_API_URL}/user/get-requests`).then((x) => {
          setRequests(x);
        });
      },
    );
  };

  useEffect(() => {
    api(`${process.env.REACT_APP_API_URL}/user`).then((x) => {
      setOwnUserData(x);
    });
  }, []);
  useEffect(() => {
    api(`${process.env.REACT_APP_API_URL}/user/get-requests`).then((x) => {
      setRequests(x);
    });
  }, []);

  return (
    <div className="flex flex-col h-full max-w-6xl mx-auto max-sm:mt-5">
      <FormModal open={inviteOpen} setOpen={setInviteOpen}>
        <div className="p-7 bg-slate-800">
          <div className="text-2xl font-semibold tracking-tight text-white">
            Invite a Friend
          </div>
          <div className="my-5 text-base font-medium text-light">
            Enter the email address of the person you would like to invite to
            KaZang. If they sign up using that email you will automatically be
            connected as friends!
          </div>
          <TextField
            label="Email Address"
            value={inviteEmail}
            onChange={(v) => setInviteEmail(v)}
          />
          <Button
            className="mt-5"
            text="Invite User"
            onClick={() => inviteUser()}
          ></Button>
        </div>
      </FormModal>
      <div className="flex items-center justify-between">
        <div className="mt-5 text-5xl font-black text-white">Friends</div>
        <Button
          text="Invite Someone New"
          onClick={() => setInviteOpen(true)}
        ></Button>
      </div>

      <div className="my-2 mt-10 text-xl font-medium text-white">
        Search For Friends
      </div>
      <div className="my-2 text-sm font-medium text-light">
        Search for existing KaZang users by their name. If you can't find
        someone on KaZang you should invite them to join!
      </div>

      <div className="flex items-baseline justify-between max-w-lg py-5 space-x-2">
        <TextField
          className="flex-1"
          placeholder={"Search..."}
          value={value}
          onChange={(val) => setValue(val)}
        ></TextField>

        <Button
          text="Search"
          className="bg-purple-400 px-5 py-1.5"
          onClick={() => getResults()}
        ></Button>
      </div>
      {data?.users?.length > 0 && (
        <div className="my-2 mt-5 text-xl font-medium text-white">Results</div>
      )}
      {data?.users?.length > 0 && (
        <div className="grid grid-cols-1 gap-4 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4">
          {data?.users?.map((x) => (
            <FriendCard
              x={x}
              ownUserData={ownUserData}
              requests={requests}
              removeContact={removeContact}
              sendRequest={sendRequest}
              acceptRequest={acceptRequest}
              rejectRequest={rejectRequest}
            />
          ))}
        </div>
      )}

      <div className="my-2 mt-5 text-xl font-medium text-white">
        Your Friends
      </div>
      <div className="grid grid-cols-1 gap-4 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4">
        {ownUserData?.contacts?.map((x) => (
          <FriendCard
            x={x}
            ownUserData={ownUserData}
            requests={requests}
            removeContact={removeContact}
            sendRequest={sendRequest}
            acceptRequest={acceptRequest}
            rejectRequest={rejectRequest}
          />
        ))}
      </div>
    </div>
  );
}
