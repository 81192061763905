import PageHeader from './components/page-header'
import Loading from './components/loading'
import { useEffect, useState } from 'react'
import { api, api_delete } from './api.service'
import { useNavigate } from 'react-router-dom'
import Button from './components/button'
import Table from './components/table'
import Confirm from './components/confirm'

export default function AdminUsers() {
  const navigate = useNavigate();
  const [data, setData] = useState(null)
  const [deleteId, setDeleteId] = useState();
  const [totalMembers, setTotalMembers] = useState(0);
  const [totalSubscribers, setTotalSubscribers] = useState(0);

  const deleteConfirm = () => {
    api_delete(`${process.env.REACT_APP_API_URL}/admin/user/${deleteId}`).then(x => {
      api(`${process.env.REACT_APP_API_URL}/admin/user`).then(x => setData(x)).then(x => setDeleteId(null))
    });
  }

  useEffect(() => { api(`${process.env.REACT_APP_API_URL}/admin/user`).then(x => setData(x)) }, []);
  useEffect(() => { api(`${process.env.REACT_APP_API_URL}/admin/member-stats`).then(x => {
    setTotalMembers(x.totalUsers);
    setTotalSubscribers(x.activeSubs);
  }) }, []);

  if (!data) return <Loading></Loading>

  return (<>
    <Confirm open={!!deleteId} cancel={() => setDeleteId(false)} confirm={() => deleteConfirm(deleteId)}></Confirm>

    <PageHeader headline={"Users"} actions={<Button text="Add User" onClick={() => navigate('/admin/manage-user/new')}></Button>}></PageHeader>

    <div className="flex">
      <div className="w-full pb-20 max-w-6xl mx-auto my-10">
        <div>

          <div className="bg-neutral-100 w-80 px-6 py-3 text-left text-sm font-semibold text-purple-500">
            {`Total Members: ${totalMembers}`}
          </div>
          <div className="bg-neutral-100 w-80 px-6 py-3 text-left text-sm font-semibold text-yellow-100">
            {`Total Subscribers: ${totalSubscribers}`}
          </div>
          <Table
              exportColumns={['Email']} exportData={data.map(x => {
            return {
              email: x.email
            }
          })}
              data={data.map(x => {
                return {
                  ...x,
                  edit: <span className="cursor-pointer underline"
                              onClick={() => navigate(`/admin/manage-user/${x._id}`)}>Edit</span>,
                  deleteCell: <span className="cursor-pointer underline"
                                    onClick={() => setDeleteId(x._id)}>Delete</span>
                }
              })} columns={[
            {
              Header: 'Email',
              accessor: 'email',
            },
            {
              Header: 'Edit',
              accessor: 'edit',
            },
            {
              Header: 'Delete',
              accessor: 'deleteCell',
            },
          ]}></Table>
        </div>
      </div>
    </div>
      </>
  )
}