import { useEffect, useState } from "react"
import Button from "../components/button";
import TextField from "./fields/text-field";
import { api } from '../api.service.js'
import Loading from "../components/loading";
import {toast} from "react-toastify";

export default function FormWrapper({ url, id, callback, children, includeIdInPost, buttonText, hideButton, noStyle, defaultValue, noId }) {
    const [values, setValues] = useState();
    const [fieldsMissing, setFieldsMissing] = useState(false);

    useEffect(() => {
        if (noId) {
            if (id){
                setValues(defaultValue ?? {})
            }
            else{
                api(`${process.env.REACT_APP_API_URL}/${url}`).then(x => {
                    setValues(x ?? {});
                });
            }
           
        }
        else {
            if (id !== "new")
                api(`${process.env.REACT_APP_API_URL}/${url}/${id}`).then(x => {
                    setValues(x ?? {});
                });
            else {
                setValues(defaultValue ?? {})
            }
        }

    }, [])

    const submit = (data) => {
        if (includeIdInPost && !noId) {
            api(`${process.env.REACT_APP_API_URL}/${url}/${id}`, { data: data ?? values }).then(x => {
                if (x?.err === 400) {
                    toast.error("Incorrect required fields");
                    setFieldsMissing(true);
                } else {
                    callback();
                }
            });
        }
        else {
            api(`${process.env.REACT_APP_API_URL}/${url}`, { data: data ?? values }).then(x => {
                if (x?.err === 400) {
                    toast.error("Incorrect required fields");
                    setFieldsMissing(true);
                } else {
                    callback();
                }
            });
        }

    }

    if (!values) return <Loading></Loading>

    return <div className={noStyle ? '' : 'max-w-6xl mx-auto my-5 bg-white shadow rounded p-5'}>
        {children(values, setValues, submit, fieldsMissing)}

        {!hideButton && <Button className="my-5" text={buttonText ?? "Submit"} onClick={() => submit()}></Button>}
    </div>
}