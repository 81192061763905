import { useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { EyeIcon, EyeSlashIcon } from '@heroicons/react/24/outline'; 
import logo from './logo.png'

export default function Login() {
  const navigate = useNavigate();
  const [values, setValues] = useState({});
  const [error, setError] = useState("")
  const [showPassword, setShowPassword] = useState(false);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const submit = async () => {
    await fetch(`${process.env.REACT_APP_API_URL}/auth/login`, {
      method: 'POST',
      headers: new Headers({
        'Content-Type': 'application/json'
      }),
      body: JSON.stringify({ user: values })
    }).then(res => { return res.json() }).then(x => {
      if (x.token) {
        localStorage.setItem("token", x.token);
        localStorage.setItem("user", JSON.stringify(x.user));
        window.location.replace(x.redirectUrl)
      }
      else {
        setError("Login failed. Please check your details and try again.")
      }
    })
  }

  return (
    <>
      <div className="flex min-h-screen flex-col bg-dark">

          <div className="flex flex-1 flex-col justify-center items-center ">
            <div className="sm:mx-auto sm:w-full sm:max-w-md cursor-pointer" onClick={() => navigate('/')}>
              <img
                className="mx-auto w-64 h-auto mb-10"
                src={logo}
              />
            </div>

            <div className="mt-4 sm:mx-auto sm:w-full sm:max-w-lg">
              <div className="bg-neutral-700 rounded py-8 px-4 shadow sm:px-10">
                <div className="font-medium text-xl text-white text-center">Sign In</div>
                <div className="font-light text-sm text-light text-center my-5">Don't have an account? <a className="text-purple-200 hover:text-purple-300" href="/register">Sign Up</a></div>
                <div className="space-y-6">
                  <div>
                    <label className="block text-sm font-medium text-red-400 text-center">
                      {error}
                    </label>
                  </div>
                  <div>
                    <label htmlFor="email" className="block text-sm font-medium text-white">
                      Email address
                    </label>
                    <div className="mt-1">
                      <input value={values.email} onChange={(e) => setValues({ ...values, email: e.target.value })}
                        id="email"
                        name="email"
                        type="email"
                        autoComplete="email"
                        required
                        className="block w-full appearance-none rounded border border-neutral-300 px-3 py-2 placeholder-neutral-400 shadow-sm focus:border-neutral-500 focus:outline-none focus:ring-neutral-500 sm:text-sm"
                      />
                    </div>
                  </div>

                  <div>
                    <label htmlFor="password" className="block text-sm font-medium text-white">
                      Password
                    </label>
                    <div className="mt-1 relative">
                      <input value={values.password} onChange={(e) => setValues({ ...values, password: e.target.value })}
                        id="password"
                        name="password"
                        autoComplete="current-password"
                        type={showPassword ? 'text' : 'password'}
                        required
                        className="block w-full appearance-none rounded border border-neutral-300 px-3 py-2 placeholder-neutral-400 shadow-sm focus:border-neutral-500 focus:outline-none focus:ring-neutral-500 sm:text-sm"
                      />
                      <button
                        type="button"
                        onClick={togglePasswordVisibility}
                        className="absolute inset-y-0 right-0 flex items-center pr-3"
                      >
                        {showPassword ? (
                          <EyeSlashIcon className="h-5 w-5 text-neutral-500" aria-hidden="true" />
                        ) : (
                          <EyeIcon className="h-5 w-5 text-neutral-500" aria-hidden="true" />
                        )}
                      </button>
                    </div>
                  </div>

                  <div className="flex items-center justify-between">
                    <div className="text-sm">
                      <a href="/request-reset" className="font-medium text-purple-300 hover:text-purple-300">
                        Forgot your password?
                      </a>
                    </div>
                  </div>

                  <div>
                    <button
                      onClick={() => submit()}
                      type="button"
                      className="flex rounded  w-full justify-center border border-transparent bg-purple-500 py-2 px-4 text-md font-semibold text-white shadow-sm hover:bg-purple-600 focus:outline-none focus:ring-2 focus:ring-purple-500 focus:ring-offset-2"
                    >
                      Login
                    </button>
                  </div>
                </div>

              </div>
            </div>
          </div>

      </div>
    </>
  )
}
