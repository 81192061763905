import { XMarkIcon } from "@heroicons/react/24/outline";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { api, api_delete } from "./api.service";
import Button from "./components/button";
import Confirm from "./components/confirm";

export default function Playlists() {
  const [data, setData] = useState();
  const navigate = useNavigate();
  const [confirmDelete, setConfirmDelete] = useState(false);
  const deletePlaylist = () => {
    api_delete(
      `${process.env.REACT_APP_API_URL}/user/playlist/${confirmDelete}`,
    ).then((x) => {
      setConfirmDelete(false);
      api(`${process.env.REACT_APP_API_URL}/user/playlists`).then((x) => {
        setData(x);
      });
    });
  };

  const [confirmLeave, setConfirmLeave] = useState(false);
  const leavePlaylist = () => {
    api(
      `${process.env.REACT_APP_API_URL}/user/playlist/leave/${confirmLeave}`,
    ).then((x) => {
      setConfirmLeave(false);
      api(`${process.env.REACT_APP_API_URL}/user/playlists`).then((x) => {
        setData(x);
      });
    });
  };

  useEffect(() => {
    api(`${process.env.REACT_APP_API_URL}/user/playlists`).then((x) => {
      setData(x);
    });
  }, []);

  return (
    <div className="flex flex-col h-full max-w-6xl mx-auto max-sm:mt-5">
      <Confirm
        open={!!confirmDelete}
        cancel={() => setConfirmDelete(false)}
        confirm={() => deletePlaylist()}
      />
      <Confirm
        open={!!confirmLeave}
        cancel={() => setConfirmLeave(false)}
        confirm={() => leavePlaylist()}
      />
      <div className="flex items-center justify-between mb-10">
        <div className="mt-5 text-5xl font-black text-white">Playlists</div>
        <Button
          text="Add New Playlist"
          onClick={() => navigate("/create-playlist/new")}
        ></Button>
      </div>

      {data?.myPlaylists?.map((x) => (
        <div onClick={() => navigate("/playlist/" + x._id)}>
          <div className="flex items-center justify-between p-3 mt-1 font-medium text-white transition border-b rounded-lg cursor-pointer border-neutral-600 hover:bg-slate-800 bg-neutral-700">
            <div>
              <div className="text-xs font-light text-light">OWNER</div>
              <div className="text-lg font-semibold text-white">{x.name}</div>
            </div>
            <div>
              <div
                onClick={(e) => {
                  e.stopPropagation();
                  setConfirmDelete(x._id);
                }}
                className="p-1 my-auto bg-red-400 rounded-full shadow opacity-100 cursor-pointer hover:bg-red-500 bottom-4 right-4"
              >
                <XMarkIcon className="w-6 text-white" />
              </div>
            </div>
          </div>
        </div>
      ))}
      {data?.memberPlaylists?.map((x) => (
        <div onClick={() => navigate("/playlist/" + x._id)}>
          <div className="flex items-center justify-between p-3 mt-1 font-medium text-white transition border-b rounded-lg cursor-pointer border-neutral-600 hover:bg-slate-800 bg-neutral-700">
            <div>
              <div className="text-xs font-light text-light">MEMBER</div>
              <div className="text-lg font-semibold text-white">{x.name}</div>
            </div>
            <div>
              <div
                onClick={(e) => {
                  e.stopPropagation();
                  setConfirmLeave(x._id);
                }}
                className="p-1 my-auto bg-red-400 rounded-full shadow opacity-100 cursor-pointer hover:bg-red-500 bottom-4 right-4"
              >
                <XMarkIcon className="w-6 text-white" />
              </div>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
}
