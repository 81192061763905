import { Popover } from '@headlessui/react'
import { InformationCircleIcon } from '@heroicons/react/20/solid'

export default function HelpPopup({ text }) {
    return (
        <Popover className="absolute inline">
            <Popover.Button><div className="absolute rounded-full left-2 top-4 bg-neutral-500"><InformationCircleIcon className="w-4 h-4 text-white"></InformationCircleIcon></div></Popover.Button>

            <Popover.Panel className="absolute z-10 w-64 p-3 text-sm text-center border shadow -left-20 rounded-xl bg-neutral-700 border-neutral-800 text-neutral-50">
                {text}
            </Popover.Panel>
        </Popover>
    )
}